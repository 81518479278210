export enum HttpMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

export type HttpError = {
  code: number;
  data: any;
};

// REQUESTS
export type SignupRequest = {
  name: string;
  surname: string;
  username: string;
  email: string;
  phone: string;
  password: string;
};

export type SignupActivationRequest = {
  username: string;
  auth_code: string;
};

export type BookingRequest = {
  date: Date;
  hour_id: number;
  court_id: number;
  type: BookingType;
  is_heated?: boolean;
  username?: string;
};

// RESPONSES
export type AuthToken = {
  access_token: string;
  token_type: string;
};

export enum UserRole {
  USER = "UTENTE",
  ADMIN = "ADMIN",
  MASTER = "MASTER",
  SERVICE = "SERVIZIO",
  MANAGER = "MANAGER",
}

export type User = {
  id: number;
  name: string;
  surname: string;
  username: string;
  role: UserRole;
};

export type SignupResponse = {
  user_id: number;
};

export enum BookingStatus {
  BOOKED = "booked",
  PAID = "paid",
}

export enum BookingType {
  SINGLE = "single",
  DOUBLE = "double",
  SOCIAL_CHAMPIONSHIP = "social_championship",
  SERVICE = "service",
}

export type Booking = {
  id: number;
  date: string;
  hour_id: number;
  hour_label: string;
  user_id: number;
  username: string;
  court_id: number;
  court_label: string;
  status: BookingStatus;
  type: BookingType;
  receipt_id: number | undefined;
  is_guest: boolean;
  is_member: boolean;
  is_heated: boolean;
};

export type Court = {
  id: number;
  name: string;
};

export type Hour = {
  id: number;
  label: string;
};
