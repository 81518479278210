import { Box, FormControl, FormLabel, Select, Stack, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Court } from "../../api/types";
import useFetchCourts from "./hooks/useFetchCourts";
import useFetchWeeks from "./hooks/useFetchWeeks";
import { Filters, Week } from "./types";

type BookingFiltersProps = {
  onFilterChange: (filters: Filters) => void;
};

const BookingFilters = ({ onFilterChange }: BookingFiltersProps) => {
  const { t } = useTranslation();
  const { courts } = useFetchCourts();
  const { weeks } = useFetchWeeks();

  const [selectedCourt, setSelectedCourt] = useState<Court | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<Week | null>(null);

  const handleCourtChange = (event: any) => {
    const courtId = parseInt(event.target.value, 10);
    const court = courts.find(court => court.id === courtId);
    if (!court || !selectedWeek) {
      return;
    }

    setSelectedCourt(court);
    onFilterChange({ court, week: selectedWeek });
  };

  const handleWeekChange = (event: any) => {
    const weekId = parseInt(event.target.value, 10);
    const week = weeks.find(week => week.id === weekId);
    if (!week || !selectedCourt) {
      return;
    }

    setSelectedWeek(week);
    onFilterChange({ court: selectedCourt, week });
  };

  useEffect(() => {
    if (courts.length > 0) {
      setSelectedCourt(courts[0]);
      setSelectedWeek(weeks[0]);
      onFilterChange({ court: courts[0], week: weeks[0] });
    }
  }, [courts]);

  return (
    <Box p={4} pb={0}>
      <Box p={2} border="1px" borderColor="gray.300" mx="auto">
        {/* Filters */}
        <Stack
          direction={["column", "row"]}
          spacing={4}
          align="center"
          justify="center"
        >
          {/* Court Selection */}
          <FormControl>
            <FormLabel>{t("booking.filters.court")}</FormLabel>
            <Select
              value={selectedCourt?.id}
              onChange={handleCourtChange}
              variant="outline"
              size="sm"
            >
              {courts.map(court => (
                <option key={court.id} value={court.id}>
                  {court.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Week Selection */}
          <FormControl>
            <FormLabel>{t("booking.filters.week")}</FormLabel>
            <Select
              value={selectedWeek?.id}
              onChange={handleWeekChange}
              variant="outline"
              size="sm"
            >
              {weeks.map(week => (
                <option key={week.id} value={week.id}>
                  {t("booking.filters.from")} {format(week.start, "dd/MM/yyyy")}{" "}
                  {t("booking.filters.to")} {format(week.end, "dd/MM/yyyy")}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>

        {/* Dynamic Label */}
        <Text fontSize="2xl" fontWeight="bold" mt={3} textAlign="center">
          <Text as="span" fontWeight="extrabold" color="teal.500">
            {selectedCourt?.name}
          </Text>
          {" - "}
          {t("booking.filters.week_from")}{" "}
          <Text as="span" fontWeight="extrabold" color="blue.500">
            {selectedWeek ? format(selectedWeek.start, "dd/MM/yyyy") : ""}
          </Text>{" "}
          {t("booking.filters.week_to")}{" "}
          <Text as="span" fontWeight="extrabold" color="blue.500">
            {selectedWeek ? format(selectedWeek.end, "dd/MM/yyyy") : ""}
          </Text>
        </Text>
      </Box>
    </Box>
  );
};

export default BookingFilters;
