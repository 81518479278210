import { useEffect } from "react";
import api from "../api";
import { ACCESS_TOKEN_KEY } from "../constants";
import useAuthStore from "../store/auth";

/**
 * a hook that check if a stored access token is valid and in case set the user in the global state
 * @returns {void}
 */
const useInitUser = (): void => {
  const [setUser] = useAuthStore(state => [state.setUser]);

  useEffect(() => {
    const initUser = async () => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (!accessToken) {
        return;
      }

      const user = await api.getCurrentUser();
      if (!user) {
        return;
      }
      setUser(user);
    };

    initUser();
  }, []);
};

export default useInitUser;
