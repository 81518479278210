import type { AxiosError, AxiosInstance } from "axios";
import axios from "axios";

import { format } from "date-fns";
import { ACCESS_TOKEN_KEY } from "../constants";
import {
  AuthToken,
  Booking,
  BookingRequest,
  Court,
  Hour,
  HttpError,
  HttpMethods,
  SignupRequest,
  SignupResponse,
  User,
} from "./types";

/**
 * Api is used to handle all interactions with the backend of this service
 */
class Api {
  instance: AxiosInstance;
  error: HttpError | undefined;
  baseUrl: string;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      timeout: 10000,
      headers: { "Content-Type": "application/json" },
    });
    this.baseUrl = baseURL;
    this.error = undefined;
  }

  private async makeApiCall<T>(
    method: HttpMethods,
    url: string,
    payload?: Record<string, unknown>,
    params?: Record<string, unknown>,
    authorized = true,
  ): Promise<T | undefined> {
    let headers = {};
    if (authorized) {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      headers = { Authorization: `Bearer ${accessToken}` };
    }
    try {
      this.error = undefined;
      const { data } = await this.instance({
        method,
        url,
        data: payload,
        params,
        headers,
      });

      return data;
    } catch (e) {
      console.log(e);
      const error = e as Error | AxiosError;
      if (axios.isAxiosError(error) && error.response) {
        this.error = { code: error.response.status, data: error.response.data };
      } else {
        this.error = { code: 500, data: error.message };
      }

      // handle token expiration
      if (this.error.code === 401) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.location.reload();
      }

      return undefined;
    }
  }

  /**
   * Perform login
   * @param {string} username user username
   * @param {string} password user password
   * @returns {AuthToken} the user authentication token
   */
  async login(username: string, password: string): Promise<AuthToken | undefined> {
    const payload = {
      username,
      password,
    };

    return this.makeApiCall<AuthToken>(
      HttpMethods.POST,
      "/auth/login",
      payload,
      undefined,
      false,
    );
  }

  /**
   * Get current logged in user
   * @returns {User} the user authenticated
   */
  async getCurrentUser(): Promise<User | undefined> {
    return this.makeApiCall<User>(HttpMethods.GET, "/users/me");
  }

  /**
   * Signup user
   * @param {SignupRequest} request signup payload
   * @returns {SignupResponse} the response from the signup procedure
   */
  async signup(request: SignupRequest): Promise<SignupResponse | undefined> {
    const payload = { ...request };

    return this.makeApiCall<SignupResponse>(
      HttpMethods.POST,
      "/auth/signup",
      payload,
    );
  }

  /**
   * Activate user
   * @param {SignupActivationRequest} request signup activation payload
   */
  async activate(username: string, authCode: string): Promise<undefined> {
    const payload = {
      username,
      auth_code: authCode,
    };

    return this.makeApiCall<undefined>(
      HttpMethods.POST,
      "/auth/signup/activate",
      payload,
    );
  }

  /**
   * Get Bookings
   * @param {Date} date_from date from filter
   * @param {Date} date_to date to filter
   * @returns {Booking[]} list of bookings
   */
  async getBookings(
    dateFrom: Date,
    dateTo: Date,
    courtId: number,
  ): Promise<Booking[] | undefined> {
    const params = {
      date_from: format(dateFrom, "yyyy-MM-dd"),
      date_to: format(dateTo, "yyyy-MM-dd"),
      court_id: courtId,
    };

    return this.makeApiCall<Booking[]>(
      HttpMethods.GET,
      "/bookings",
      undefined,
      params,
    );
  }

  /**
   * Request a new booking
   * @param {BookingRequest} request booking request payload
   */
  async requestBooking(request: BookingRequest): Promise<Booking | undefined> {
    const payload = { ...request, date: format(request.date, "yyyy-MM-dd") };

    return this.makeApiCall<Booking>(HttpMethods.PUT, "/bookings", payload);
  }

  /**
   * Cancel a booking
   * @param {number} bookingId identifier of the booking
   */
  async cancelBooking(bookingId: number): Promise<undefined> {
    return this.makeApiCall<undefined>(
      HttpMethods.POST,
      `/bookings/${bookingId}/cancel`,
    );
  }

  /**
   * Get courts
   * @returns {Court[]} list of courts
   */
  async getCourts(): Promise<Court[] | undefined> {
    return this.makeApiCall<Court[]>(HttpMethods.GET, "/courts");
  }

  /**
   * Get hours
   * @returns {Hour[]} list of hours
   */
  async getHours(): Promise<Hour[] | undefined> {
    return this.makeApiCall<Hour[]>(HttpMethods.GET, "/hours");
  }
}

export default Api;
