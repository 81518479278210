import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../api";
import Logo from "../../components/logo/logo";
import ActivationFailed from "../../components/signup/activation-failed";
import ActivationSuccesful from "../../components/signup/activation-successful";

const ActivationPage = () => {
  const [searchParams] = useSearchParams();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const activateUser = async () => {
      const username = searchParams.get("user");
      const authCode = searchParams.get("code");

      if (!username || !authCode) {
        setHasError(true);
        return;
      }

      // activate user
      await api.activate(username, authCode);
      if (api.error) {
        setHasError(true);
      }
    };

    activateUser();
  }, []);

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {/* Navbar */}
      <Flex as="nav" bg="gray.800" py={4} justifyContent="center">
        <Logo />
      </Flex>

      {/* Success Message */}
      <Box
        flex="1"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="gray.50"
      >
        {!hasError && <ActivationSuccesful />}
        {hasError && <ActivationFailed />}
      </Box>
    </Box>
  );
};

export default ActivationPage;
