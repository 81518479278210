import { Court } from "../../api/types";

export type Filters = {
  court: Court;
  week: Week;
};

export type Week = {
  id: number;
  start: Date;
  end: Date;
};

export enum CellColor {
  DEFAULT = "white",
  PAID = "green.300",
  UNPAID = "red.300",
  SERVICE_HOUR = "yellow.300",
}
