import { WarningIcon } from "@chakra-ui/icons"; // Import the warning icon
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../components/logo/logo";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {/* Navbar */}
      <Flex as="nav" bg="gray.800" py={4} justifyContent="center">
        <Logo />
      </Flex>

      {/* Not Found Message */}
      <Box
        flex="1"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="gray.50"
        pt={8}
      >
        <Container maxW="md" textAlign="center">
          <VStack spacing={4} align="center">
            {/* Warning Icon */}
            <Icon as={WarningIcon} color="orange.400" boxSize={14} mb={4} />
            <Heading as="h1" size="lg" mb={2}>
              {t("not_found.page_not_found")}
            </Heading>
            <Text fontSize="lg" mb={2}>
              {t("not_found.main_message")}
            </Text>
            <Text fontSize="lg" color="gray.600" mb={4}>
              {t("not_found.sub_message")}
            </Text>
            <Button colorScheme="green" size="lg" onClick={handleGoHome}>
              {t("not_found.go_home")}
            </Button>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
