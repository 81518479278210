import { Box, Heading } from "@chakra-ui/react";
import { ReactElement } from "react";
import logoIcon from "../../assets/logo-tct.jpg";

type LogoProps = {
  zIndex?: number;
};

const Logo = ({ zIndex }: LogoProps): ReactElement => {
  return (
    <Box display="flex" alignItems="center" zIndex={zIndex}>
      {/* TODO: remove background color */}
      <img src={logoIcon} alt="Logo" width="40" height="40" />
      {/* TODO: change color to green */}
      <Heading as="h2" size="lg" ml="1rem" color="white">
        Tennis Club Trebaseleghe
      </Heading>
    </Box>
  );
};

export default Logo;
