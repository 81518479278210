import { useToast } from "@chakra-ui/react";

/**
 * a hook that allows to show success and error toasts
 * @returns {void}
 */
const useShowToast = () => {
  const toast = useToast();

  const successToast = (options: { title?: string; description?: string }) => {
    toast({
      title: options.title,
      description: options.description,
      status: "success",
      variant: "subtle",
      isClosable: true,
      containerStyle: { whiteSpace: "pre-line" },
    });
  };

  const errorToast = (options: { title?: string; description?: string }) => {
    toast({
      title: options.title,
      description: options.description,
      status: "error",
      variant: "subtle",
      isClosable: true,
      containerStyle: { whiteSpace: "pre-line" },
    });
  };

  return { successToast, errorToast };
};

export default useShowToast;
