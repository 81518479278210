import { addDays, getTime, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";
import { HttpError } from "../../../api/types";
import { Week } from "../types";

/**
 * Custom hook to fetch weeks
 * @returns {object} - Contains weeks, loading state, error state, and refetch function.
 */
function useFetchWeeks(weekInTheFuture = 4, weekInThePast = 0) {
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchWeeks = async () => {
    setLoading(true);
    setError(null);

    try {
      const weeks: Week[] = [];

      const start = startOfWeek(new Date(), { weekStartsOn: 1 });
      const end = addDays(start, 6);

      // TODO: get weekInThePast and weekInTheFuture from backend
      for (let i = weekInThePast; i < weekInTheFuture; i++) {
        weeks.push({
          id: getTime(addDays(start, i * 7)),
          start: addDays(start, i * 7),
          end: addDays(end, i * 7),
        });
      }

      setWeeks(weeks);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeeks();
  }, []);

  return { weeks, loading, error, refetchWeeks: fetchWeeks };
}

export default useFetchWeeks;
