import { useEffect, useState } from "react";
import api from "../../../api";
import { Court, HttpError } from "../../../api/types";

/**
 * Custom hook to fetch courts from backend.
 * @returns {object} - Contains courts, loading state, error state, and refetch function.
 */
function useFetchCourts() {
  const [courts, setCourts] = useState<Court[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchCourts = async () => {
    setLoading(true);
    setError(null);

    try {
      const courts = (await api.getCourts()) || [];

      if (api.error) {
        setError(api.error);
      }

      setCourts(courts);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourts();
  }, []);

  return { courts, loading, error, refetchCourts: fetchCourts };
}

export default useFetchCourts;
