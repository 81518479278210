enum ApiError {
  SIGNUP_USER_ALREADY_EXISTS = "signup_user_already_exists",
  SIGNUP_CANNOT_CREATE_USER = "signup_cannot_create_user",
  BOOKING_MISSING_USERNAME = "booking_missing_username",
  BOOKING_ALREADY_BOOKED = "booking_already_booked",
  BOOKING_INVALID_HOUR = "booking_invalid_hour",
  BOOKING_PAST_DATE = "booking_past_date",
}

export default ApiError;
