import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../../constants";
import useAuthStore from "../../store/auth";
import LoginModal from "../login/login-modal";
import Logo from "../logo/logo";

const Header = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isOpen: isLoginModalOpen,
    onOpen: onLoginModalOpen,
    onClose: onLoginModalClose,
  } = useDisclosure();

  const [user, setUser] = useAuthStore(state => [state.user, state.setUser]);

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    setUser(null);
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        padding="1rem"
        bg="gray.800"
      >
        {/* logo */}
        <Flex align="center">
          <Logo />
        </Flex>
        {/* action buttons */}
        {!user && (
          <Box>
            <Button colorScheme="twitter" mr={4} onClick={onLoginModalOpen}>
              {t("navbar.login")}
            </Button>
            <Button colorScheme="green" onClick={goToSignup}>
              {t("navbar.signup")}
            </Button>
            {/* TODO: add instruction/tutorial button */}
          </Box>
        )}

        {/* User profile */}
        {user && (
          <Menu>
            <MenuButton as={Button}>
              <Text marginRight="2" fontSize="2xl">
                {t("navbar.welcome")}, {user.name}
              </Text>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>

      {/* login modal */}
      <LoginModal isOpen={isLoginModalOpen} onClose={onLoginModalClose} />
    </>
  );
};

export default Header;
