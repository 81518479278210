import { CalendarIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../assets/tennis-court-background.png";
import Header from "../components/header/header";

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToBooking = () => {
    navigate("/booking");
  };

  return (
    <>
      <Box display="flex" flexDirection="column" height="100vh">
        {/* Header Component */}
        <Header />

        {/* Main content area with background */}
        <Box
          flex="1" // This makes the background section fill the remaining height
          backgroundImage={backgroundImage}
          backgroundSize="cover"
          backgroundPosition="center"
          position="relative"
        >
          {/* Overlay with blur effect */}
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            backdropFilter="blur(4px)" // Apply blur to the background
            zIndex={0}
            backgroundColor="rgba(0, 0, 0, 0.4)"
          />

          {/* Foreground content */}
          <Center flexDirection="column" height="100%" zIndex={2} textAlign="center">
            <Heading
              color="white"
              fontSize="5xl"
              fontWeight="bold"
              className="linebreak"
              mb={8}
              zIndex={2}
            >
              {t("home.welcome")}
            </Heading>

            <Heading color="white" fontSize="4xl" mb={8} zIndex={2}>
              {t("home.book_yout_court")}
            </Heading>

            <Button
              size="xl"
              colorScheme="blue"
              variant="solid"
              zIndex={2}
              leftIcon={<CalendarIcon />}
              onClick={handleGoToBooking}
              fontSize="2xl"
              px="8"
              py="5"
            >
              {t("home.book_now")}
            </Button>
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
