import { useEffect, useState } from "react";
import api from "../../../api";
import { Booking, HttpError } from "../../../api/types";

/**
 * Custom hook to fetch bookings from backend.
 * @returns {object} - Contains bookings, loading state, error state, and refetch function.
 */
function useFetchBooking(dateFrom: Date, dateTo: Date, courtId: number) {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchBookings = async (dateFrom: Date, dateTo: Date, courtId: number) => {
    setLoading(true);
    setError(null);

    try {
      const bookings = (await api.getBookings(dateFrom, dateTo, courtId)) || [];

      if (api.error) {
        setError(api.error);
      }

      setBookings(bookings);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings(dateFrom, dateTo, courtId);
  }, []);

  return { bookings, loading, error, refetchBookings: fetchBookings };
}

export default useFetchBooking;
