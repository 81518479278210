import i18next from "i18next";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import "./index.css";
import IT from "./translations/it.json";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "it",
  fallbackLng: "it",
  resources: {
    it: {
      translation: IT,
    },
  },
  keySeparator: ".",
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
);
