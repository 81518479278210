import { useEffect, useState } from "react";
import api from "../../../api";
import { Hour, HttpError } from "../../../api/types";

/**
 * Custom hook to fetch hours from backend.
 * @returns {object} - Contains hours, loading state, error state, and refetch function.
 */
function useFetchHours() {
  const [hours, setHours] = useState<Hour[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchHours = async () => {
    setLoading(true);
    setError(null);

    try {
      const hours = (await api.getHours()) || [];

      if (api.error) {
        setError(api.error);
      }

      setHours(hours);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHours();
  }, []);

  return { hours, loading, error, refetchHours: fetchHours };
}

export default useFetchHours;
