import { ChakraProvider } from "@chakra-ui/react";
import type { ReactElement } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import useInitApp from "./hooks/useInitApp";
import BookingPage from "./pages/booking";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/not-found";
import ActivationPage from "./pages/signup/activation";
import SignupPage from "./pages/signup/signup";

const App = (): ReactElement => {
  useInitApp();

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signup/activation" element={<ActivationPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
