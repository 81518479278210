import { useState } from "react";
import BookingFilters from "../components/booking/booking-filters";
import BookingTable from "../components/booking/booking-table";
import { Filters } from "../components/booking/types";
import Header from "../components/header/header";

const BookingPage = () => {
  const [filters, setFilters] = useState<Filters | undefined>(undefined);

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
  };

  return (
    <>
      <Header />
      <BookingFilters onFilterChange={handleFilterChange} />
      {filters && <BookingTable filters={filters} />}
    </>
  );
};

export default BookingPage;
