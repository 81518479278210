import create, { SetState } from "zustand";
import { User } from "../api/types";

type AuthStore = {
  user: User | null;
  setUser: (user: User | null) => void;
};

const useAuthStore = create<AuthStore>((set: SetState<AuthStore>) => ({
  accessToken: null,
  user: null,
  setUser: (user: User | null) => set(state => ({ user })),
}));

export default useAuthStore;
