import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import LoginForm from "./login-form";

type LoginModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const LoginModal = ({ isOpen, onClose }: LoginModalProps): ReactElement => {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          {/* header */}
          <ModalHeader>Login</ModalHeader>
          <ModalCloseButton />

          {/* body */}
          <ModalBody>
            <LoginForm closeModal={closeModal} />
          </ModalBody>

          {/* footer */}
          <ModalFooter>
            <Button colorScheme="green" width="100%" form="login-form" type="submit">
              Login
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginModal;
