import { Box, Container, Flex } from "@chakra-ui/react";
import { useState } from "react";
import Logo from "../../components/logo/logo";
import ConfirmEmail from "../../components/signup/confirm-email";
import SignupForm from "../../components/signup/signup-form";

const SignupPage = () => {
  const [completed, setCompleted] = useState(false);

  const onCompleted = () => {
    setCompleted(true);
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {/* Navbar */}
      <Flex as="nav" bg="gray.800" py={4} justifyContent="center">
        <Logo />
      </Flex>

      {/* Signup Form */}
      <Box
        flex="1"
        overflowY="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="gray.50"
        pt={8}
      >
        <Container maxW="lg" height="100%">
          {!completed && <SignupForm onCompleted={onCompleted} />}
          {completed && <ConfirmEmail />}
        </Container>
      </Box>
    </Box>
  );
};

export default SignupPage;
