import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BookingCell from "./booking-cell";
import "./booking-table.css";
import useFetchBooking from "./hooks/useFetchBookings";
import useFetchHours from "./hooks/useFetchHours";
import { Filters } from "./types";
import { findBooking, getDays } from "./utility";

type BookingTableProps = {
  filters: Filters;
};

const BookingTable = ({ filters }: BookingTableProps) => {
  const { t } = useTranslation();
  const { bookings, refetchBookings } = useFetchBooking(
    filters.week.start,
    filters.week.end,
    filters.court.id,
  );

  const { hours } = useFetchHours();
  const daysOfWeek = getDays(filters.week.start);

  useEffect(() => {
    refetchBookings(filters.week.start, filters.week.end, filters.court.id);
  }, [filters]);

  return (
    <Box p={4}>
      <Table variant="simple" size="sm" className="booking-table">
        {/* Table header */}
        <Thead>
          <Tr>
            <Th p={2} width="5%" textAlign="center">
              {t("booking.time")}
            </Th>
            {daysOfWeek.map(day => (
              <Th key={format(day, "EEE")} p={2} width="10%" textAlign="center">
                {t(`booking.days.${format(day, "EEE")}`)}
              </Th>
            ))}
          </Tr>
        </Thead>

        {/* Table content */}
        <Tbody>
          {hours.map(hour => (
            <Tr key={hour.id}>
              <Td p={1} fontWeight="bold" textAlign="center" whiteSpace="nowrap">
                {hour.label}
              </Td>
              {daysOfWeek.map(day => {
                const booking = findBooking(bookings, day, hour.id);
                return (
                  <Td key={`${day}-${hour}`} p="0">
                    <BookingCell
                      date={day}
                      hour={hour}
                      court={filters.court}
                      booking={booking}
                      onBookingCompleted={() =>
                        refetchBookings(
                          filters.week.start,
                          filters.week.end,
                          filters.court.id,
                        )
                      }
                      onBookingEditCompleted={() =>
                        refetchBookings(
                          filters.week.start,
                          filters.week.end,
                          filters.court.id,
                        )
                      }
                    />
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default BookingTable;
