import { CheckCircleIcon } from "@chakra-ui/icons";
import { Button, Container, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ActivationSuccesful = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container maxW="md" textAlign="center">
      <VStack spacing={6}>
        <Icon as={CheckCircleIcon} color="green.400" boxSize={14} mb={4} />
        <Heading as="h1" size="lg">
          {t("activation.successful.email_confirmed")}
        </Heading>
        <Text fontSize="lg">{t("activation.successful.main_message")}</Text>
        <Text fontSize="lg" color="gray.600">
          {t("activation.successful.sub_message")}
        </Text>
        <Button colorScheme="green" size="lg" onClick={handleGoHome}>
          {t("activation.successful.go_home")}
        </Button>
      </VStack>
    </Container>
  );
};

export default ActivationSuccesful;
