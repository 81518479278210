import { addDays, format, startOfWeek } from "date-fns";
import { Booking } from "../../api/types";

export const getDays = (week: Date) => {
  const startOfThisWeek = startOfWeek(week, { weekStartsOn: 1 }); // Start week on Monday

  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    addDays(startOfThisWeek, i),
  );

  return daysOfWeek;
};

export const findBooking = (bookings: Booking[], date: Date, hourId: number) => {
  return bookings.find(
    booking =>
      booking.date === format(date, "yyyy-MM-dd") && booking.hour_id === hourId,
  );
};
