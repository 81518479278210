import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import api from "../../api";
import { Booking } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/auth";

enum EditAction {
  CANCEL = "cancel",
}

type BookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  booking: Booking;
  onBookingEditCompleted?: () => void;
};

const BookingEditModal = ({
  isOpen,
  onClose,
  booking,
  onBookingEditCompleted = () => null,
}: BookingModalProps) => {
  const { t } = useTranslation();
  const [user] = useAuthStore(state => [state.user]);
  const { successToast, errorToast } = useShowToast();

  const showSuccessToast = (action: EditAction) => {
    successToast({
      title: t(`booking.modal_edit.${action}.success_title`),
      description: t(`booking.modal_edit.${action}.success_message`),
    });
  };

  const showErrorToast = (action: EditAction, errorCode: any) => {
    const description = t(
      `booking.modal_edit.${action}.error_message.${errorCode.detail}`,
      {
        defaultValue: t(`booking.modal_edit.${action}.error_message.default`),
      },
    );

    errorToast({
      title: t(`booking.modal_edit.${action}.error_title`),
      description,
    });
  };

  const cancelBooking = async () => {
    await api.cancelBooking(booking.id);
    if (api.error) {
      console.log("Error canceling booking", api.error);
      onClose();
      showErrorToast(EditAction.CANCEL, api.error.data);
      return;
    }

    // Close modal
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.CANCEL);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        {/* Modal title */}
        <ModalHeader>{t("booking.modal_edit.edit_booking")}</ModalHeader>

        <Divider />

        {/* Modal Body */}
        <ModalBody>
          <Text mb={4}>{t("booking.modal_edit.edit_message")}</Text>

          {/* Common fields */}
          <Text>
            <Text as="span" fontWeight="bold">
              {t("booking.modal.court")}:
            </Text>{" "}
            {/* TODO: show court label */}
            <Text as="span">{booking.court_label}</Text>
          </Text>
          <Text>
            <Text as="span" fontWeight="bold">
              {t("booking.modal.date")}:
            </Text>{" "}
            <Text as="span">{format(booking.date, "dd/MM/yyyy")}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.hour")}:
            </Text>
            {"   "}
            <Text as="span">{booking.hour_label}</Text>
          </Text>

          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.type")}:
            </Text>
            {"  "}
            <Text as="span">{t(`booking.modal.${booking.type}`)}</Text>
          </Text>
        </ModalBody>

        <Divider />

        {/* Modal Footer with buttons */}
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t("booking.modal_edit.btn_cancel")}
          </Button>
          <Button colorScheme="orange" ml={3} onClick={cancelBooking}>
            {t("booking.modal_edit.cancel.btn_title")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingEditModal;
